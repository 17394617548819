<template>
  <div class="scheme-contain">
    <div class="data-contain">
      <div class="card-wrapper">
        <div class="monitor-search">
          <div class="monitor-date">
            <Date-picker format="yyyy-MM-dd" v-model="time" type="daterange" placeholder="选择日期" @on-change="handleChangeDate" style="width:200px;"></Date-picker>
          </div>
          <div class="tab-contain">
            <router-link class="static-button" :to="{name: 'Train'}">统计显示</router-link>
            <router-link :to="{name: 'TrainTable'}">数据显示</router-link>
            <router-link class="chart-button" :to="{name: 'TrainReport'}">训练报表</router-link>
          </div>
        </div>
      </div>
      <div class="add-box">
        <Row>
          <Col span="2" offset="22">
            <div class="member-oprate" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
              <Button type="primary" @click="makeReport">生成训练报表</Button>
            </div>
          </Col>
        </Row>
      </div>

      <div class="table-box">
        <Table :columns="columns" :data="trainList" ref="table" @on-selection-change="handleSelect"></Table>

        <div>
          <span class="records">共{{pageTotal}}条记录</span>
        </div>
      </div>

      <div class="page-nations-wrapper">
        <div class="page-nations">
          <Page :total="pageTotal" :current="cur_page" :page-size="trainData.size" @on-change="changePage"></Page>
        </div>
      </div>
    </div>
    <Modal
      :title="`心率曲线 - ${modalTitle}`"
      v-model="showModal"
      class-name="vertical-center-modal"
      width="800"
    >
      <div class="modal-chart" ref="chartRef" style="height: 300px;"></div>
      <div slot="footer">
        <Button type="primary" @click.native="showModal = false">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script type="text/ecmascript-6">
import echarts from 'echarts';
import MemberService from '@/services/memberService';
import huaweiService from '@/services/huaweiService';

export default {
	data() {
		return {
			time: [],
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '训练日期', key: 'xlsj', align: 'center' },
				{ title: '训练项目', key: 'xlxm', align: 'center' },
				{ title: '开始时间', key: 'start', align: 'center' },
				{ title: '训练时长', key: 'sportTime', align: 'center' },
				{ title: '训练时最大心率', key: 'maxHeart', align: 'center' },
				{ title: '平均心率', key: 'avgHeart', align: 'center' },
				{ title: '总热量消耗', key: 'calories', align: 'center' },
				{
					title: '心率曲线',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h('a', {
							class: 'icon_eye',
							style: { marginRight: '0' },
							on: {
								click: () => {
									const { id, xlsj } = params.row;
									this.showModal = true;
									this.modalTitle = xlsj;
									huaweiService.getHeartRate({ jl_id: id }).then((data) => {
										echarts.init(this.$refs.chartRef).setOption({
											color: ['#fdad00'],
											grid: {
												top: 20,
												right: 20,
												bottom: 20,
												left: 40,
											},
											tooltip: { trigger: 'axis' },
											xAxis: {
												type: 'category',
												data: data.heart_x,
											},
											yAxis: { type: 'value' },
											series: [
												{
													data: data.heart_y,
													type: 'line',
													smooth: true,
												},
											],
										});
									});
								},
							},
						});
					},
				},
			],
			trainData: {
				index: 1,
				start: '',
				end: '',
				page: 1,
				size: 10,
				monitor_id: '',
			},
			trainList: [],
			pageTotal: 0,
			cur_page: 1,
			selectedItem: [],
			power: [],
			showModal: false,
			modalTitle: '',
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
		monitorId() {
			return this.$route.params.monitorId;
		},
	},
	methods: {
		firstDate() {
			let d = new Date();
			return `${d.getFullYear()}-${d.getMonth() + 1}-01`;
		},
		nowDate() {
			let d = new Date();
			let month = d.getMonth() + 1;
			month = month > 9 ? month : '0' + month;
			let day = d.getDate();
			day = day > 9 ? day : '0' + day;
			return `${d.getFullYear()}-${month}-${day}`;
		},
		last30Date() {
			// 获取当前日期
			var myDate = new Date();

			// 获取三十天前日期
			var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);
			var lastY = lw.getFullYear();
			var lastM = lw.getMonth() + 1;
			var lastD = lw.getDate();
			var startdate =
				lastY + '-' + (lastM < 10 ? '0' + lastM : lastM) + '-' + (lastD < 10 ? '0' + lastD : lastD);

			return startdate;
		},
		handleInit() {
			this.trainData.member_id = this.memberId;
			MemberService.zhaikeQuery(this.trainData).then((data) => {
				this.selectedItem = [];
				this.trainList = data.list;
				this.pageTotal = data.row_size;
				this.cur_page = +data.cur_page;
				this.trainList.forEach((item) => {
					item.xlsj = item.xlsj.substring(0, 16);
				});
			});
		},
		changePage(page) {
			this.trainData.page = page;
			this.handleInit();
		},
		handleChangeDate(value) {
			this.trainData.start = value[0];
			this.trainData.end = value[1];
			this.handleInit();
		},
		handleSelect(selection) {
			this.selectedItem = selection.map((item) => item.record_id);
		},
		makeReport() {
			const amount = this.selectedItem.length;
			if (amount > 1 && amount < 5) {
				const params = {
					member_id: this.memberId,
					ids: JSON.stringify(this.selectedItem),
				};
				MemberService.makeTrainReport(params).then(() => {
					this.$router.push({ name: 'TrainReport' });
				});
			} else {
				this.$Message.error('请选择 2-4 条数据');
			}
		},
		// 删除一个会员
		handleSingleRemove(index) {
			let req = [this.trainList[index].id];
			MemberService.transDel({ id_str: JSON.stringify(req) }).then(() => {
				this.handleInit();
				this.$Message.warning('删除成功');
			});
		},
		// 删除多个会员
		handleAllRemove() {
			if (this.selectedItem.length === 0) {
				this.$Message.warning('请先勾选要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选数据',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},
		// 确认删除
		handleConfirmDelete() {
			MemberService.transDel({ id_str: JSON.stringify(this.selectedItem) }).then(() => {
				this.handleInit();
				this.selectedItem = [];
			});
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.time = [this.last30Date(), this.nowDate()];
		this.trainData.start = this.time[0];
		this.trainData.end = this.time[1];
		this.trainData.monitor_id = this.monitorId;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.data-contain .table-box {
  margin: 0;
}
.scheme-contain .data-contain {border: none;}
</style>
